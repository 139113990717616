import { fromJS } from 'immutable';

const initialState = fromJS({
  loads: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'ADD_LOADS': {
      const sortedLoads = fromJS(action.loads).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });
      return state.set('loads', sortedLoads);
    }

    case 'REMOVE_LOAD': {
      const removableLoad = state.get('loads').findIndex(load => load.get('id') === fromJS(action.load));
      if (removableLoad !== -1) {
        return state.set('loads', state.get('loads').delete(removableLoad));
      }
      return state;
    }
  
    case 'ADD_LOAD': {
     const newLoad = fromJS(action.load);
      if (state.get('loads').find(load => load.get('id') === newLoad.get('id')) === undefined) {
        return state.set('loads', state.get('loads').push(newLoad));
      }
      return state;
    }

    case 'CHANGE_LOAD': {
      const changingIndex = state.get('loads').findIndex(load => load.get('id') === fromJS(action.load.id));
      
      if (changingIndex === -1) return state;

      if (state.get('loads').get(changingIndex) === fromJS(action.load)) {
        return state;
      }

      const newLoads = state.get('loads').set(changingIndex, fromJS(action.load));
      return state.set('loads', newLoads);
    }

    default: {
      return state;
    }
  }
};