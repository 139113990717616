import React from 'react';
import { connect } from 'react-redux';
import { changeLoad, removeLoadById, addLoads } from './Actions';
import Excel from '../../../../src/excel/Excel'
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import List from '../../../../src/list/List';
import TopContractAndSiteSelect from '../../../../src/topContractAndSiteSelect/TopContractAndSiteSelect';
import { fetch } from '../utils';
import ChangeLoadView from './Components';
import './Load.css';


class Loads extends React.Component {
  
  constructor(props) {
    super(props);

    this.getLoads(this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('id') : null);

    this.state = {
      loading: true,
      loadMasses: []
    };

    this.getLoads = this.getLoads.bind(this);
    this.goChangeLoad = this.goChangeLoad.bind(this);
    this.clearChangeLoad = this.clearChangeLoad.bind(this);
    this.removeLoad = this.removeLoad.bind(this);
    this.confirmRemoveLoad = this.confirmRemoveLoad.bind(this);
    this.toggleMakeExcelLoad = this.toggleMakeExcelLoad.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedContract === null || nextProps.selectedConstructionSite === null) {
      this.getLoads(null);
      return;
    }

    if (nextProps.selectedConstructionSite == null || this.props.selectedConstructionSite === nextProps.selectedConstructionSite) return;

    this.getLoads(nextProps.selectedConstructionSite.get('id'));
  }

  getLoads(constructionSite) {
    this.setState({
      loading: true
    });

    let url = '/coatingplantloads';

    if (constructionSite != null) {
      url += '/site/' + constructionSite;
    }

    fetch(url).then(data => {
      this.props.addLoads(data);
    }).catch(error => {
      this.props.showNotice('Palvelimeen ei saatu yhteyttä', 'Error');
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  goChangeLoad(loadId) {
    const load = this.props.loads.find(load => load.get('id') === loadId);
    this.setState({
      changingLoad: load
    });
  }

  clearChangeLoad() {
    this.setState({
      changingLoad: null,
    });
  }

  removeLoad() {
    fetch('/coatingplantloads/' + this.state.removingLoad + '/', 'DELETE').then(data => {
      this.props.showNotice('Kuorma poistettu', 'Ok')
      this.props.removeMassById(this.state.removingLoad);
    }).catch(error => {
      this.props.showMessage('Virhe', 'Kuorman poisto epäonnistui', 'Error');
    });
  }

  confirmRemoveLoad(loadId) {
    this.setState({
      removingLoad: loadId,
    });
    this.props.showConfirm('Poistetaanko Kuorma?', this.removeLoad);
  }

  toggleMakeExcelLoad() {
    this.setState({ 
      makeExcelLoad: !this.state.makeExcelLoad
    })
  }

  render() {
    return (
      <div>
        <div className="container">
          <h1>Kuormat</h1>
          <div className="row center">
            <div className="column">
              <button onClick={this.toggleMakeExcelLoad}>Luo raportti</button>
            </div>
          </div>
          <List emptyText={'Ei yhtään kuormaa'}
                header={['Rekisteri-numero', 'Massa', 'Laatu', 'Huomiot', 'Aika', '', '']}
                fields={['truck', 'total_mass#round', 'quality', 'attentions',
                         'date#time', 'button|Muokkaa|0#id', 'button|Poista|1#id']}
                data={this.props.loads}
                functions={[this.goChangeLoad, this.confirmRemoveLoad]}/>
        </div>
        <ChangeLoadView load={this.state.changingLoad} clear={this.clearChangeLoad} store={this.props.store} />
        <Excel show={this.state.makeExcelLoad} toggle={this.toggleMakeExcelLoad}
               name={'Aseman kuormat'}
               headers={['Rekisteri-numero', 'Massa', 'Laatu', 'Huomiot', 'Aika']}
               dataHeaders={['truck', 'total_mass', 'quality', 'attentions', 'date']}
               timeField={'date'}
               data={this.props.loads}/>
        <TopContractAndSiteSelect store={this.props.store} />
      </div>
    );
  }
}


export default connect(state => ({
  loads: state.load.get('loads'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, changeLoad, removeLoadById, addLoads, showConfirm })(Loads);
