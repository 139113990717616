import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import L from 'leaflet';
import { useMap, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import List from '../../../../src/list/List';
import TopContractAndSiteSelect from '../../../../src/topContractAndSiteSelect/TopContractAndSiteSelect';
import TruckSelect from '../../../../src/truckSelect/TruckSelect.js';
import { selectTruck } from '../../../../src/truckSelect/Actions.js';
import MapTiles from '../../../../src/mapTiles/MapTiles.js';
import { changeLoad } from './Actions';
import { fetch, integerValue, toETRSTM35FIN } from '../utils.js';
import 'leaflet/dist/leaflet.css';

const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;

export const Input = props => {
  return (
    <div>
      <TopContractAndSiteSelect store={props.store} />
      <div className='row'>
        <div className='column'>
          <label htmlFor='truck'>Rekka</label>
          { props.truck == null ?
              <TruckSelect required store={props.store} />
          :
            <input id='truck' type='text' value={props.truck}
              onFocus={props.resetTruck}
              onChange={props.resetTruck} /** onChange needed to remove react error message *//>
          }
        </div>
        <div className='column'>
        <label htmlFor='mass'>Massa</label>
        <input id='mass' type='tel'
           onChange={props.changeState.bind(this, 'mass', 'float', 0.0)} value={props.mass || ''} required/>
        </div>
        <div className='column'>
          <label htmlFor='quality'>Laatu</label>
          <input id='quality' type='text' value={props.quality}
            onChange={props.changeState.bind(this, 'quality', 'string', '')}/>
        </div>
      </div>
      <div className='row'>
        <div className='column'>
          <label htmlFor='attentions'>Huomiot</label>
          <input id='attentions' type='text' value={props.attentions}
            onChange={props.changeState.bind(this, 'attentions', 'string', '')}/>
        </div>
      </div>
    </div>
  );
};


class ChangeLoadView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      truck: null,
      mass: 0,
      quality: '',
      attentions: ''
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeLoad = this.doChangeLoad.bind(this);
    this.resetTruck = this.resetTruck.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.load == null) return;

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truck: nextProps.selectedTruck.get('register_number'),
        mass: nextProps.selectedTruck.get('default_mass'),
      });
    }

    if (this.props.load !== nextProps.load)
    this.setState({
      truck: nextProps.load.get('truck'),
      mass: nextProps.load.get('total_mass'),
      quality: nextProps.load.get('quality'),
      attentions: nextProps.load.get('attentions'),
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    let value;
    if (type === 'integer') {
      value = integerValue(event.target.value, defaultValue);
    }
    else if (type === 'float') {
      value = event.target.value.replace(',', '.');
      const regExp = /^\d*\.?\d*$/;
      if (!regExp.test(value)) {
        return;
      }
    }
    else {
      value = event.target.value;
    }
    
    let state = this.state;
    state[propertyName] = value;
    this.setState(state);
  }

  doChangeLoad() {
    if (this.state.truck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    if (this.state.mass === 0) {
      this.props.showNotice('Massa määrää ei ole annettu', 'Warning');
      return;
    }

    let load = this.props.load;
    load = load.set('truck', this.state.truck);
    load = load.set('total_mass', parseFloat(this.state.mass));
    load = load.set('quality', this.state.quality);
    load = load.set('attentions', this.state.attentions);

    fetch('/coatingplantloads/' + load.get('id') + '/', 'PATCH', load).then(data => {
      this.props.showNotice('Kuorma muokattu', 'Ok');
      this.props.changeLoad(data);
    }).catch(error => {
      this.props.showMessage('Virhe', 'Kuorman muokkaus epäonnistui', 'Error');
    }).then(() => {
      this.props.clear();
    });
  }

  resetTruck() {
    this.props.selectTruck(null);
    localStorage.truck = null;
    this.setState({
      truck: null
    });
  }

  render() {
    if (this.props.load == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Kuorman muokkaus</h3>
          <Input
            changeState={this.changeState}
            mass={this.state.mass}
            truck={this.state.truck}
            quality={this.state.quality}
            attentions={this.state.attentions}
            resetTruck={this.resetTruck}
            store={this.props.store}
          />
          <button onClick={this.doChangeLoad}>Hyväksy muutokset</button>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck')
}), { selectTruck, showNotice, showMessage, changeLoad })(ChangeLoadView);


export const LoadDataOfMass = props => {
  if (props.data.length === 0) return null;

  let totalStone1 = 0;
  let totalStone2 = 0;
  let totalStone3 = 0;
  let totalStone4 = 0;
  let totalBitumen = 0;
  let totalPoly = 0;
  let totalFiller = 0;
  let totalCrush = 0;
  let totalFibre = 0;
  let totalMass = 0;
  let totalSize = 0;

  for (let index in props.data) {
    totalStone1 += props.data[index]['stone1'];
    totalStone2 += props.data[index]['stone2'];
    totalStone3 += props.data[index]['stone3'];
    totalStone4 += props.data[index]['stone4'];
    totalBitumen += props.data[index]['bitumen'];
    totalPoly += props.data[index]['dust'];
    totalFiller += props.data[index]['filler'];
    totalCrush += props.data[index]['crush'];
    totalFibre += props.data[index]['fibre'];
    totalMass += props.data[index]['mass'];;
    totalSize += props.data[index]['size'];
  }

  return (
    <div onClick={props.clear} className='modal'>
      <div id='load-data-modal'>
        <h4 className='center'>Kuorman tiedot (Tonneina)</h4>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                Kivi 1
              </th>
              <th>
                Kivi 2
              </th>
              <th>
                Kivi 3
              </th>
              <th>
                Kivi 4
              </th>
              <th>
                Bitumi
              </th>
              <th>
                Pöly
              </th>
              <th>
                Täyteaine
              </th>
              <th>
                Rouhe
              </th>
              <th>
                Kuitu
              </th>
              <th>
                Tonnit yhteensä
              </th>
              <th>
                Annoskoko
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Yhteensä:</b>
              </td>
              <td>
                {Math.round(totalStone1*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone2*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone3*10000)/10000}
              </td>
              <td>
                {Math.round(totalStone4*10000)/10000}
              </td>
              <td>
                {Math.round(totalBitumen*10000)/10000}
              </td>
              <td>
                {Math.round(totalPoly*10000)/10000}
              </td>
              <td>
                {Math.round(totalFiller*10000)/10000}
              </td>
              <td>
                {Math.round(totalCrush*10000)/10000}
              </td>
              <td>
                {Math.round(totalFibre*10000)/10000}
              </td>
              <td>
                {Math.round(totalMass*10000)/10000}
              </td>
              <td>
                {Math.round(totalSize*10000)/10000}
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className='center'>Erittely lastauksista:</h5>
        <List id='test'
              header={['Aika', 'Rekka', 'Resepti', 'Kivi 1', 'Kivi 2', 'Kivi 3',
                      'Kivi 4', 'Bitumi', 'Pöly', 'Täyteaine', 'Rouhe', 'Kuitu',
                      'Lämpötila', 'Tonnit', 'Annoskoko', 'Huomiot']}
              fields={['time_stamp#time', 'truck', 'recipe', 'stone1#round', 'stone2#round',
                      'stone3#round', 'stone4#round', 'bitumen#round', 'dust#round', 'filler#round',
                      'crush#round', 'fibre#round', 'tempature#round', 'mass#round', 'size', 'attentions']}
              data={fromJS(props.data)}/>
      </div>
    </div>
  );
};

export const MapView = (properties) => {
  const props = properties;

  let thisTrucks = [];
  let position = [64.1, 26.5];
  let zoom = 6;
  let map = null;

  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [maxZoom, setMaxZoom] = useState(16);
  const [trucks, setTrucks] = useState([]);
  const [height, setHeight] = useState();
  const [selectedTruck, setSelectedTruck] = useState();

  useEffect(() => {
    updateDimensions();
    update(props);
    window.addEventListener("resize", updateDimensions());

    return () => {
      window.removeEventListener("resize", updateDimensions)
    };
  }, [])

  const update = (props) => {
    thisTrucks = [];

    trucks.forEach(truck => {
      const date = new Date(truck['time']);
      const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

      let loaded = false;

      if (props.loads) {
        loaded = props.loads.includes(load => load.get('truck') === truck['vehicleId']);
      }

      thisTrucks.push(
        <Marker key={truck['vehicleId']}
          position={[truck['latitude'], truck['longitude']]}
          icon={new L.Icon({
            iconUrl: loaded ? 'truck_loaded.gif' : 'truck.gif',
            iconSize: [45, 36],
            iconAnchor: [23, 36],
            popupAnchor: [null, -36]
          })}>
          <Popup autoPan={false}>
            <span>
              {truck['vehicleId']}
            </span>
            <br />
            <span>
              Viimeisin aika: {time}
            </span>
          </Popup>
        </Marker>
      );
    });
  }

  const updateDimensions = () => {
    const height = window.innerHeight * 0.6;
    setHeight(height);
  }

  const getTrucks = () => {
    fetch('/vehicles').then(data => {
      setTrucks(data);
    }).catch(error => {

    });
  }

  const selectTruck = (index) => {
    setSelectedTruck(index);
  }

  useEffect(() => {
    update(props);
  }, [trucks, selectedTruck]);

  function MapControl() {
    map = useMap();

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng);
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }
  }

  return (
    <div>
      {window.dispatchEvent(new Event('resize'))}
      <div style={{ height: height }}>
        <MapContainer id="map-area" center={position}
          zoom={zoom} maxZoom={maxZoom}>
          <MapControl />
          <TileLayer url={mapTilesUrl}
            attribution={mapTilesAttribution}
            maxZoom={maxZoom} />
          {props.site != null && props.site.get('coating_plant_laditude') != null ? (
            <Marker position={[props.site.get('coating_plant_laditude'), props.site.get('coating_plant_longitude')]}
              icon={new L.Icon({
                iconUrl: 'coating_plant.gif',
                iconSize: [33, 50],
                iconAnchor: [17, 50],
                popupAnchor: [null, -50]
              })}>
              <Popup autoPan={false}>
                <span>{props.site.get('coating_plant_laditude')}, {props.site.get('coating_plant_longitude')}</span>
              </Popup>
            </Marker>
          ) : null}
          {props.yourLatitude != null ? (
            <Marker position={[props.yourLatitude, props.yourLongitude]}
              icon={new L.Icon({
                iconUrl: '/your_location.gif',
                iconSize: [18, 43],
                iconAnchor: [9, 43],
                popupAnchor: [null, -43]
              })}>
              <Popup autoPan={false}>
                <span>{props.yourLatitude}, {props.yourLongitude}</span>
              </Popup>
            </Marker>
          ) : null}
          {trucks}
        </MapContainer>
      </div>
    </div>
  );
};